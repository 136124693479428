// src/Home.js
import React from 'react';
import { Link } from 'react-scroll'; // Import Link from react-scroll

function Home() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50" id="home">
      <h2 className="text-2xl font-semibold mb-4 text-black">Welcome to TechThrill Solutions</h2>
      <p className="text-lg text-gray-700 mb-8 text-center px-4">
        We provide top-notch IT consulting services to help your business thrive in the digital age.
      </p>
      <Link
        to="services" // Target section
        smooth={true}
        duration={500}
        className="bg-black text-white px-6 py-2 rounded-full hover:bg-gray-800 transition duration-300 cursor-pointer"
      >
        Learn More
      </Link>
    </div>
  );
}

export default Home;
