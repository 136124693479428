import React from 'react';

function About() {
  return (
    <section className="py-20 min-h-screen flex flex-col items-center justify-center bg-gray-50">
      <h2 className="text-2xl font-semibold mb-4 text-black">About Us</h2>
      <p className="text-lg text-gray-700 mb-8 text-center px-4">
        TechThrill Solutions LLP is a leading IT consulting firm dedicated to providing innovative solutions to help businesses thrive in the digital age.
      </p>
    </section>
  );
}

export default About;
